.footer__container {
    display: flex;
    flex-wrap: wrap;
    background: #040C18;
    border-top: solid 1.5px #FF8A71;
    border-radius: 5px;
    width: 100%;
}

.footer__right {
    display: flex;
    width: 100%;
    min-width: 50%;
    justify-content: center;
    font-size: 18px;
    color: var(--color-logoOrange);
    font-family: var(--font-family);
    font-weight: 500;
    letter-spacing: 1.5px;
}

li:hover {
    color: silver;
}

.nav1 ul {
    list-style: circle;
    margin-right: 80px;
}

.nav2 ul{
    list-style: circle;
}

.footer__container li  {
    cursor: pointer;
    text-shadow: black 1px 1.5px;
}

.footer__nav li {
    list-style: none;
    font-family: var(--font-family);
    font-weight: 300;
    color: aliceblue;
    letter-spacing: 0.88px;
}

.footer__left {
    width: 100%;
    min-width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.signUpText {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.signUpText h3 {
    padding-top: 25px;
    text-align: center;
    width: 100%;
    color: antiquewhite;
    font-family: var(--font-family);
    font-weight: 300;
    font-size: 42px;
}

.signUpText p {
    text-align: center;
    color: limegreen;
    font-family: var(--font-family);
    font-weight: 300;
    letter-spacing: 0.5px;
    font-size: 20px;
}

.footer__centered {
    width: 100%; 
}

.certifications {
    display: flex;
}

.footer__logo {
    display: flex;
    justify-content: space-around;
    width: 100%;
}

.cert__logo {
    display: flex;
    justify-content: center;
    padding: 0px 0px 10px 0px;
}

.cert__logo img {
    padding: 1px;
    border: solid 2px rgb(151, 148, 148);
    border-radius: 7px;
}

.header__content__input {
    width: 100%;
    margin: 1rem 0;
    display: flex;
    max-width: 333px;
}

.header__content__input input {
    background: var(--color-blog);
    border-radius: 8px 0px 0px 8px ;
    font-family: var(--font-family);
    border: solid 1.5px var(--color-footer);
    padding: .5rem .5rem;
    outline: none;
    font-weight: 300;
    font-size: 20px;
    line-height: 20px;
    letter-spacing: 1px;
    width: 60%;
    min-width: fit-content;
    flex: 0.6;
    color:antiquewhite;
}

.header__content__input button {
    background: var(--color-logoOrange);
    font-size: 20px;
    color: white;
    font-family: var(--font-family);
    font-weight: lighter;
    padding: .5rem .5rem;
    border: solid 1.5px var(--color-footer);
    border-radius: 0px 8px 8px 0px ;
    letter-spacing: .5px;
    outline: none;
    cursor: pointer;
    flex: 0.4;
    width: 100%;
    min-width: fit-content;
}

.copywrite {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.copywrite h5 {
    color: rgb(94, 94, 94);
    font-weight: 300;
    font-size: 16px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.copywrite p {
    color: rgb(94, 94, 94);
    font-weight: 300;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 16px;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #fff;
    opacity: 1; /* Firefox */
}

  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: rgb(255, 255, 255);
}

  ::-ms-input-placeholder { /* Microsoft Edge */
    color: rgb(255, 255, 255);
}

.footer__top {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    flex-wrap: nowrap;
    align-items: center;
}

@media screen and (max-width: 768px) {
    .footer__top {
        flex-direction: column;
    }

    .footer__right {
        padding-left: 0px;
    }
    .footer__right ul{
        list-style: none;
        margin: 0;
        padding: 15px;
    }
}

@media screen and (max-width: 400px) {
    .header__content__input input,  .header__content__input button {
        font-size: 16px;
    }
}