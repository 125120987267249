.blog {
    padding: 3rem;
    background: linear-gradient(45deg, rgb(37, 153, 221), rgb(145, 185, 164));
    overflow: hidden;
}

.blog h1 {
    color: white;
    text-shadow: 2px 2px 10px rgb(6, 6, 6);
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-weight: 300;
    letter-spacing: .23rem;
    text-align: center;
    padding-bottom: 10px;
}

.blog p {
    color: white;
    text-shadow: 1px 2px 10px rgb(6, 6, 6);
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-weight: 300;
    letter-spacing: .08rem;
    text-align: center;
    padding-bottom: 40px;
}

.card {
    display: flex;
    justify-content: center;
    align-items: center;
}


.card1 {
    width: 100%;
    height: 300px;
    background-image: linear-gradient(45deg,
    rgba(131, 131, 135, 0.75),
    rgba(177, 177, 177, 0.75)), url('../../assets/misc/LVCES.jpeg');
    background-size: contain;

    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    margin: 10px;
}

.card2 {
    width: 100%;
    height: 300px;
    background-image: linear-gradient(45deg,
    rgba(131, 131, 135, 0.75),
    rgba(177, 177, 177, 0.75)), url('../../assets/misc/blog2.jpg');
    background-size: contain;


    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    margin: 10px;
}

.card1 h2 {
    font-size: 45px;
    color: aliceblue;
    text-shadow: 1px 2px 8px black;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-weight: 600;
    letter-spacing: 1px;
    text-align: center;
}

.card2 h2 {
    font-size: 45px;
    color: aliceblue;
    text-shadow: 1px 2px 8px black;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-weight: 600;
    letter-spacing: 1px;
    text-align: center;
}

.card1:hover {
    transform: scale(1.03);
    border: 3px solid white
}

.card2:hover {
    transform: scale(1.03);
    border: 3px solid white
}

.post {
    display: none;
}

@media screen and (max-width:550px){
    .blog {
        display: flex;
        flex-direction: column;
        padding: .5rem;
        justify-content: center;
        align-items: center;
    }

    .card1, .card2 {
        max-width: 450px;
        margin: 10px 0px;
    }
    
}