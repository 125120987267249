:root {
    --font-family: 'Kanit', sans-serif;

    --gradient-text: linear-gradient(89.97deg, #170ce1 1.84%, #f16822 102.67%);
    --gradient-bar: linear-gradient(103.22deg, #0e12d4 -13.86%, #f16822 99.55%);

    --color-bg: #040C18;
    --color-footer : #031B34;
    --color-blog: #042c54;
    --color-text: #81AFDD;
    --color-subtext: #FF8A71;
}

.gradient__text {
    background: var(--gradient-text);
    background-clip: text;
    -webkit-background-clop: text;
    -webkit-text-fill-color: transparent;
}

.section__padding {
    padding: 10rem 15rem;
}

.design {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    background:#040C18;
    overflow: hidden;
}

.design__heading {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    text-align: left;
    margin-right: 2rem;
    margin-left: 1rem;
}

.design__heading h1 {
    font-size: 50px;
    font-family: var(--font-family);
    font-weight: 300;
    line-height: 55px;
    max-width: 888px;
}

.design .impressions {
    width: 100%;
    font-size: 33px;
    font-family: var(--font-family);
    letter-spacing: .3rem;
    margin-bottom: 50px;
}

.design__heading p {
    font-family: var(--font-family);
    font-size: 23px;
    color: rgb(150, 230, 191);
    text-shadow: 1px 1px 18px lime;
    padding: 15px 0px 55px 0px;
}

.design__features h1 {
    white-space: nowrap;
    font-family: var(--font-family);
    color: lime;
    letter-spacing: .3rem;
    font-weight: 600;
    font-size: 2rem;
}

.design__features p {
    color: white;
    font-family: 'Courier New', Courier, monospace;
    font-weight: 400;
    font-size: 23px;
}

.design__features {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 10px;
}

@media screen and (max-width: 1810px ) {
    .section__padding{
        padding: 8rem 2rem
    }
} 

@media screen and (max-width: 970px ) {

    .design__heading h1 {
        min-width: 200px;
        font-size: 38px;
        line-height: 40px;
        letter-spacing: .08rem;
    }

    .design__heading h1 {
        font-size: 33px;
    }

    .design__features h1 {
        font-size: 24px;
    }

    .section__padding {
        padding: 8rem 1rem;
    }

    .design .impressions {
        font-size: 26px;
        line-height: 44px;
    }
}

@media screen and (max-width: 888px ) {

    .design__features {
    flex: 1;
    min-width: 375px;
}
}

@media screen and (max-width: 797px ) {

    .design__heading p {
        padding: 15px 0px 20px 0px;
    }

    .section__padding {
        padding: 8rem 1rem;
    }

    .design__heading h1 {
        font-size: 30px;
        line-height: 30px;
        min-width: 350px;
    }

    .design .impressions {
        font-size: 26px;
        line-height: 48px;
        text-align: center;
    }
}

@media screen and (max-width: 450px ) {
    .section__padding {
        padding: 8rem 0rem;
    }

    .design__heading {
        margin: .08rem;
        text-align: center;
    }

    .design__heading h1 {
        font-size: 25px;
        text-align: center;
    }

    .design .impressions {
        font-size: 22px;
        letter-spacing: .2rem;
        line-height: 40px;
        text-align: center;
    }

    .design__features {
        height: ;
    }

}

