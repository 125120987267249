.scheduler {
    width: 100vw;
    background-color: #444;
    display: flex;
    justify-content: center;
    padding: 1rem;
}

.calendar {
    width: 90vw;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.calendar h3 { 
    color: aliceblue;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 30px;
    font-weight: 300;
}

.calendar p { 
    color: rgb(178, 181, 185);
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 18px;
}


.calendar_btn {
    display: flex;
    align-items: center;
    justify-content: center;
}

.calendar_btn a {
    color: aliceblue;
    padding: 10px;
    font-size: 23px;
    background-color: rgb(50, 247, 93);
    border-radius: 5px;
    white-space: nowrap;
}

@media screen and (max-width:650px) {
    .calendar {
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .calendar_btn {
        margin: 10px 0px;
    }
}