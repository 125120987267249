.zombiePage {
    padding: 2rem 2rem;
    background-image: linear-gradient(100deg,
    rgba(21, 21, 21, 0.865),
    rgba(26, 24, 24, 0.772)), url('../../assets/misc/startup-idea.png');

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.zombiePage h1 {
    font-size: 50px;
    color: rgb(73, 239, 18);
    text-shadow: 1px 2px 8px rgb(137, 129, 56);
    font-family: fantasy;
    text-align: center;
}

@media screen and (max-width: 568px) {
    .zombiePage {
        padding: none;
    }

    .zombiePage h1 {
        font-size: 20px;
    }

    .zombie img {
        height: 300px;
        width: 250px;
    }
}