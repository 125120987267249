@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@300;500;700&display=swap');

:root {
    --font-family: 'Kanit', sans-serif;

    --gradient-text: linear-gradient(89.97deg, #1a1cc0 1.84%, #f16822 102.67%);
    --gradient-bar: linear-gradient(103.22deg, #181c8b -13.86%, #f16822 99.55%);

    --color-bg: #040C18;
    --color-footer : #031B34;
    --color-blog: #042c54;
    --color-text: #76a0b8;
    --color-subtext: #FF8A71;
    --color-logoOrange: #f26821;
}

.section__padding {
    padding: 11rem 8rem;
}

.gradient__text {
    background: var(--gradient-text);
    background-clip: text;
    -webkit-background-clop: text;
    -webkit-text-fill-color: transparent;
}

.gradient__text__desk {
    background: var(--gradient-text);
    background-clip: text;
    -webkit-background-clop: text;
    -webkit-text-fill-color: transparent;
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-around; 
    border-bottom: solid 2px var(--color-subtext);
    border-radius: 7px;
}

.header__content h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 70px;
    line-height: 75px;
    letter-spacing: .05rem;
    white-space: nowrap;
}

.header__content h2 {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 70px;
    line-height: 75px;
    letter-spacing: .13rem;
    white-space: nowrap;
    color: lime;
    
}

.header__content h6 {
    font-family: var(--font-family);
    font-weight: 300;
    font-size: 20px;
    line-height: 27px;
    color: var(--color-text);
    max-width: 800px;
    letter-spacing: .088rem;
    text-shadow: solid 1px 1px 8px lime;
}

.header__content__input {
    width: 100%;
    margin: 2rem 0;
    display: flex;
    max-width: 600px;
}

.header__content__input input {
    background: var(--color-blog);
    border-radius: 8px 0px 0px 8px ;
    font-family: var(--font-family);
    border: solid 1.5px var(--color-footer);
    padding: .5rem 1rem;
    outline: none;
    font-weight: 300;
    font-size: 23px;
    line-height: 30px;
    letter-spacing: 1px;
    flex: 2;
    width: 100%;
    color:antiquewhite;
}

.header__content__input button {
    background: var(--color-logoOrange);
    font-size: 23px;
    color: white;
    font-family: var(--font-family);
    font-weight: 300;
    padding: .5rem 1rem;
    border: solid 1.5px var(--color-footer);
    border-radius: 0px 8px 8px 0px ;
    letter-spacing: 1px;
    outline: none;
    cursor: pointer;
    flex: 0.6;
    width: 100%;
    min-width: fit-content;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #fff;
    opacity: 1; /* Firefox */
}

  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: rgb(255, 255, 255);
}

  ::-ms-input-placeholder { /* Microsoft Edge */
    color: rgb(255, 255, 255);
}

.header__content__people p {
    font-size: 14px;
    font-family: var(--font-family);
    font-weight: 300;
    letter-spacing: .13rem;
    color:rgb(251, 251, 251);
    text-align: center;
}

.mobile__logo {
    display: none;
}

.mobile {
    display: none;
}

.header__logo {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

/*SVG Animation image */
.cd__svg {
    width: 629px;
    height: 576px;
    }

/*Logo Solid image */    
.solid__logo {
    padding-top: 4px;
    width: 629px;
    height: 578px;
    opacity: 1;
    z-index: 1;
    animation: fadeInAnimation ease 5s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.svg__container {
    stroke: rgb(255, 255, 255);
    stroke-width: 7;
    stroke-linecap: round;
}

svg{
    position: absolute;
}

.path{
    stroke-dasharray: 1380;
    stroke-dashoffset: 1380;
    animation: dash 3.5s linear forwards;
}

@keyframes dash{
    from{
    stroke-dashoffset: 1380;
    }
    to{
    stroke-dashoffset: 0;
    }
}

@media  screen and (max-width: 1450px) {
    .header {
        flex-direction: column;
    }

    .header__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0 0 3rem;
    }

    .header__content h2 {
        display: none;
    }

    .section__padding {
        padding: 1rem 2rem;
    }

    .header__logo__desktop {
        display: none;
    }

    .mobile__logo {
        width: 100%;
        display: inline;
        padding: 0.5rem;
    }

    .section__padding {
        padding: 1rem;
    }

    .gradient__text__desk {
        display: none;
    }

    .mobile {
        display: inline;
    }

    .header .gradient__text {
        display: flex;
        justify-content: center;
    }

    .header h2 {
        width: 100%;
        font-size: 38px;
        line-height: 35px;
        text-align: left;
        font-family: var(--font-family);
        padding-right: 333px;
    }

    .header h3 {
        width: 100%;
        text-align: center;
        font-size: 42px;
        line-height: 42px;
        letter-spacing: 1px;
        white-space: nowrap;
        font-family: var(--font-family);
    }

    .header h4 {
        display: flex;
        justify-content: center;
        font-family: var(--font-family);
        color: 	#00ff00d5 !important ;
        font-size: 48px;
        line-height: 20px;
        text-align: center;
        letter-spacing: 1px;
        width: 100%;
    }

    .header h6 {  
        text-align: center;
        padding-top: 25px;
        display: flex;
        width: 100%;
        color: var(--color-text);
    }

    .header__content__people {
        flex-direction: column;
    }
    .header__content__people p { 
        margin: 0;
    }
    
    .header__content__input input,
    .header__content__input button {
        font-size: 16px;
        line-height: 23px;
    }

    .cd__svg {
        width: 510px;
        height: 478px;
        padding: 0;
        }
    
    .solid__logo {
        width: 520px;
        height: 478px;
        padding: 0;
    }
}

@media screen and (max-width: 1000px) {
    .header__content h1{
        white-space: pre-line;
    }

    .header__logo__desktop {
        display: none;
    }

    .mobile__logo {
        width: 100%;
        display: inline;
        padding: 0.5rem;
    }
}

@media  screen and (max-width: 650px) {
    .section__padding {
        padding: 1rem;
    }

    .header h1 {
        font-size: 52px;
        line-height: 42px;
        width: 100%;
        text-align: center;
    }

    .header h2 {
        width: 100%;
        font-size: 42px;
        line-height: 35px;
        text-align: left;
        padding-right: 222px;
    }

    .header h3 {
        width: 100%;
        text-align: center;
        line-height: 42px;
        letter-spacing: 1px;
        white-space: pre-wrap;
    }

    .header h4 {
        font-family: var(--font-family);
        color: 	#00ff00d5 !important ;
        font-size: 46px;
        line-height: 10px;
        text-align: center;
        width: 100%;
    }

    .header p { 
        font-size: 16px;
        line-height: 24px;
    }

    .header__content__people {
        flex-direction: column;
    }
    .header__content__people p { 
        margin: 0;
    }
    
    .header__content__input input,
    .header__content__input button {
        font-size: 16px;
        line-height: 23px;
    }

    .cd__svg {
        width: 390px;
        height: 345px;
    }

    .solid__logo {
        padding-top: 3px;
        width: 378px;
        height: 350px;
    }
}

@media  screen and (max-width: 550px) {

    .header p { 
        font-size: 14px;
        line-height: 24px;
        margin-top: 5px;
    }

    .header__content__input {
        margin-bottom: 0;
    }

    .header__content__input input,
    .header__content__input button {
        font-size: 12px;
        line-height: 16px;
    }

    .cd__svg {
        width: 280px;
        height: 245px;
    }

    .solid__logo {
        padding-top: 5px;
        width: 268px;
        height: 250px;
    }

    .header h3 {
        font-size: 32px;
        line-height: 34px;
    }
}

@media  screen and (max-width: 400px) {

    .header h1 {
        font-size: 45px;
        white-space: nowrap;
    }

    .header h2 {
        font-size: 30px;
        padding-right: 160px;
    }
}

@media  screen and (max-width: 350px) {

    .header h1 {
        font-size: 35px;
        white-space: nowrap;
    }

    .header h2 {
        font-size: 30px;
        padding-right: 160px;
    }
}