.av__container__feature {
    display: flex;
    min-width: 210px;
    justify-content: space-between;
    flex-direction: column;
    margin: 5px;
    padding: 20px;
    border: outset 4px rgb(156, 219, 219);
    border-radius: 15px;
}

.av__container__feature__title {
    flex: 1;
    margin-bottom:  1rem;
}

.av__container__feature__title h1 {
    font-family: var(--font-family);
    margin-left: 13px;
}

.av__container__feature__title div {
    width: 5rem;
    height: 4px;
    background: linear-gradient(45deg, rgb(143, 220, 233), rgb(155, 107, 167)) ;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    margin-bottom: 0.25rem;
}

.av__container__feature__text {
    flex: 2;
    max-width: 490px;
    display: flex;
}

.av__container__feature__text p {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
}

