@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@300;500;700&display=swap');

:root {
    --font-family: 'Kanit', sans-serif;

    --color-bg: #040C18;
    --color-footer : #031B34;
    --color-blog: #042c54;
    --color-text: #81AFDD;
    --color-subtext: #FF8A71;
}

.cd__navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 4rem;
}

.navbar__links {
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
}

.navbar__links__logo {
    display: flex;
    justify-content: flex-start;
    margin-right: 25px;
}

.navbar__links__logo img {
    width: 444px;
    height: 100px;
    cursor: crosshair;
}

.navbar__links__container {
    display: flex;
    gap: 1.3rem;
    margin-right: 25px;
    white-space: nowrap;
}

.navbar__links__container p,
.navbar__sign p,
.navbar__menu__container p {
    color: #fff;
    font-family: var(--font-family);
    font-weight: 300;
    line-height: 25px;
    font-size: 19px;
    text-transform: capitalize;
    margin: 0 1rem;
    cursor: pointer;
    text-align: center;
}

.navbar__links__container p:hover, .navbar__menu__container p:hover {
    text-decoration: underline;
    transform: scale(1.2);
}

.navbar__menu {
    margin-right: 1rem;
    display: none;
    position: relative;
}

.navbar__menu svg {
    cursor: pointer;
    position: relative;
}

.navbar__menu__container {
    z-index: 2;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    text-align: end;
    background: var(--color-footer);
    padding: 2rem;
    position: absolute;
    top: 40px;
    right: 0;
    margin-top: 1rem;
    min-width: 210px;
    border-radius: 10px;
    box-shadow: 0 0 5 rgba(0, 0, 0, 0.2);
}

.navbar__menu__container p {
    margin: 1rem 0;
}

.navbar__menu__container__links{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
}

.scale__up__center {
	-webkit-animation: scale__up__center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    animation: scale__up__center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

@-webkit-keyframes scale__up__center {
0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
}
100% {
    -webkit-transform: scale(1);
    transform: scale(1);
}
}

@keyframes scale__up__center {
0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
}
100% {
    -webkit-transform: scale(1);
    transform: scale(1);
}
}

@media screen and (max-width: 1390px) {
    .navbar__links__container {
        display: none;
    }

    .navbar__menu {
        display: flex;
    }
    
    .navbar__links__logo img {
        width: 333px;
        height: 100px;
    }
    
}

@media screen and (max-width: 700px) {
    .cd__navbar {
        padding: 2rem 2rem;
    }

    .navbar__links__logo img {
        width: 300px;
        height: 80px;
    }
    
}

@media screen and (max-width: 550px) {
    .cd__navbar {
        padding: 1.5rem;
    }
    .navbar__sign{
        display: none;
    }
    .navbar__menu__container{
        top: 30px;
    }
    .navbar__menu__container__links__sign{
        display: flex;
        flex-direction: column;
        align-items: center;
        border: solid 2.3px var(--color-subtext);
        border-radius: 23px;
    }
    
    .navbar__menu__container__links__sign p{
        padding-bottom: 6px;
        border-bottom: #fff solid 1px;
    }
    
    .navbar__menu__container__links__sign button{
        padding: .5rem 1.5rem;
        margin: 20px;
    }

    .navbar__links__logo img {
        width: 280px;
        height: 70px;
    }
}

@media screen and (max-width: 380px) {
    .navbar__links__logo img {
        width: 250px;
        height: 55px;
    }
    
    .navbar__menu__container__links__sign button{
        padding: .5rem 1rem;
    }
    .cd__navbar {
    padding: .5rem 1rem;
    border-bottom: solid black 1px;
    border-radius: 5px;
}
}