.career {
    height: 50vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: url('../../assets/misc/grey.jpg');
    background-size: cover;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    text-align: center;
}

.career h1 {
    color: lime;
    padding: 10px;
    font-size: 50px;
    font-weight: 500;
    letter-spacing: .3rem;
    
}

.career h2 {
    color: rgb(194, 191, 192);
    font-weight: 300;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    padding: 10px;
}

.career h3 {
    color:rgb(194, 191, 192);
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    padding: 10px;
    font-weight: 300;
}

.career button {
    padding: 15px;
    margin: 20px;
    border-radius: 15px;
    border: none;
    background: linear-gradient(90deg, rgba(87, 248, 0, 0.257), rgba(51, 248, 2, 0.506));
    color: rgb(254, 254, 254);
    letter-spacing: 2px;
    font-weight: 600;
    font-size: 18px;
}

.career button:hover {
    background: linear-gradient(90deg, rgb(87, 248, 0), rgba(51, 248, 2, 0.996));
    color: black;
}