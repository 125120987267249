.cube__container {
    height: 100%;
    width: 100%;
    max-height: 200px;
    display: flex;
    align-items: center;
    margin-bottom: 180px;
}

.cubespinner {
    animation-name: spincube;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-duration: 12s;
    transform-style: preserve-3d;
    transform-origin: 150px 150px 0;
    margin-left: calc(50% - 150px);
}

.cubespinner div {
    width: 300px;
    height: 300px;
    border: 4px solid rgba(255, 255, 255, 0.89);
    background: rgba(32, 32, 32, 0.952);
    text-align: center;
    font-size: 220px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
}

.face1 {
    transform: translateZ(150px);
    color: #dd0031;
}
.face2 {
    transform: rotateY(90deg) translateZ(150px);
    color: #5ed4f4;
}
.face3 {
    transform: rotateY(90deg) rotateX(90deg) translateZ(150px);
    color: #28a4d9;
}
.face4 {
    transform: rotateY(180deg) rotateZ(90deg) translateZ(150px);
    color: #f06529;
}
.face5 {
    transform: rotateY(-90deg) rotateZ(90deg) translateZ(150px);
    color: #efd81d;
}
.face6 {
    transform: rotateX(-90deg) translateZ(150px);
    color: #ec4d28;
}

@keyframes spincube {
from,
to {
    transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
}
16% {
    transform: rotateY(-90deg) rotateZ(90deg);
}
33% {
    transform: rotateY(-90deg) rotateX(90deg);
}
50% {
    transform: rotateY(-180deg) rotateZ(90deg);
}
66% {
    transform: rotateY(-270deg) rotateX(90deg);
}
83% {
    transform: rotateX(90deg);
}
}

@media screen  and (max-width: 1100px){

    .cubespinner {
        transform-origin: 125px 125px 0;
        margin: calc(50% - 125px);
    }

    .cubespinner div {
        width: 250px;
        height: 250px;
        font-size: 200px;
    }
    .face1 {
        transform: translateZ(125px);
        color: #dd0031;
    }
    .face2 {
        transform: rotateY(90deg) translateZ(125px);
        color: #5ed4f4;
    }
    .face3 {
        transform: rotateY(90deg) rotateX(90deg) translateZ(125px);
        color: #28a4d9;
    }
    .face4 {
        transform: rotateY(180deg) rotateZ(90deg) translateZ(125px);
        color: #f06529;
    }
    .face5 {
        transform: rotateY(-90deg) rotateZ(90deg) translateZ(125px);
        color: #efd81d;
    }
    .face6 {
        transform: rotateX(-90deg) translateZ(125px);
        color: #ec4d28;
    }
}

@media screen  and (max-width: 830px){

    .cubespinner {
        transform-origin: 110px 110px 0;
        margin: calc(50% - 110px);
    }

    .cubespinner div {
        width: 220px;
        height: 220px;
        font-size: 180px;
    }

    .face1 {
        transform: translateZ(110px);
        color: #dd0031;
    }
    .face2 {
        transform: rotateY(90deg) translateZ(110px);
        color: #5ed4f4;
    }
    .face3 {
        transform: rotateY(90deg) rotateX(90deg) translateZ(110px);
        color: #28a4d9;
    }
    .face4 {
        transform: rotateY(180deg) rotateZ(90deg) translateZ(110px);
        color: #f06529;
    }
    .face5 {
        transform: rotateY(-90deg) rotateZ(90deg) translateZ(110px);
        color: #efd81d;
    }
    .face6 {
        transform: rotateX(-90deg) translateZ(110px);
        color: #ec4d28;
    }
}


@media screen  and (max-width: 468px) {

.cubespinner {
        transform-origin: 100px 100px 0;
        margin: calc(50% - 100px);
    }
    
.cubespinner div {
    width: 200px;
    height: 200px;
    font-size: 150px;
}
.face1 {
    transform: translateZ(100px);
    color: #dd0031;
}
.face2 {
    transform: rotateY(90deg) translateZ(100px);
    color: #5ed4f4;
}
.face3 {
    transform: rotateY(90deg) rotateX(90deg) translateZ(100px);
    color: #28a4d9;
}
.face4 {
    transform: rotateY(180deg) rotateZ(90deg) translateZ(100px);
    color: #f06529;
}
.face5 {
    transform: rotateY(-90deg) rotateZ(90deg) translateZ(100px);
    color: #efd81d;
}
.face6 {
    transform: rotateX(-90deg) translateZ(100px);
    color: #ec4d28;
}
}