.post {
    margin: 8rem 5rem;
    padding: 1rem;
    background-color: aliceblue;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    letter-spacing: .5px;
    display: flex;
    flex-direction: column;
    border: solid rgb(130, 127, 127) 5px;
    border-radius: 15px;
    overflow: hidden;
}

.post h3 img {
    max-width: 300px;
    background: rgb(0, 0, 0);
    padding: 6px 10px 10px 10px;
    margin: 5px;
    border-radius: 15px;
}

.post img:hover {
    background: rgb(63, 63, 63);
    transform: scale(1.1);
}

.post .postDate {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 50px; 
}

.post .postAuthor {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 25px;
}

.post .postAuthor span {
    padding: 10px;
    text-align: center;
}

.post .postAuthor button {
    width: 100px;
    height: 30px;
    font-size: 15px;
    border-radius: 10px;
    color: aliceblue;
    background: black;
    margin-top: 15px;
}

.post .postDate span {
    color: #444;
    font-size: 14px;
}

.post .postDate button {
    background-color: black;
    color: aliceblue;
    padding: 13px;
    border: none;
    font-weight: 500;
    font-size: 23px;
    border-radius: 5px; 
}

.post .postDate button:hover {
    background-color: rgb(243, 239, 239);
    color: rgb(0, 0, 0);
    border: solid black 1px;
}

.post p {
    font-size: 18px;
    padding: 15px;
    letter-spacing: 1px;
}

.post li {
    padding: 5px 0px;
}

.post h1, h2 {
    color: black;
}

.post h1 {
    font-size: 44px;
    text-align: center;
    font-weight: 500;
    padding-bottom: 15px;
    text-shadow: 1px 2px 10px rgb(152, 152, 152);
    letter-spacing: .08rem;
}

.post h2 {
    font-size: 32px;
    text-align: center; 
}

.post h3 {
    width: 100%;
    text-align: center;
    font-size: 33px;
}

.infoContent:nth-child(2) {
    background: rgba(185, 225, 237, 0.478);
    border-radius: 15px;
}

.infoContent {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem 0rem;
    flex-wrap: wrap;
}

.post .textArea {
    padding: 1.5rem;
    max-width: 1100px;
}

@media screen and (max-width: 1000px) {

    .post {
        margin: 1rem;
        padding: .5rem;
    }

    .infoContent {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 2rem 0;
        flex-wrap: wrap;
    }
}

@media screen and (max-width: 768px) {
    .post img {
        max-height: 400px;
        max-width: 500px;
    }

    .post p {
        font-size: 16px;
    }

    .post .postDate button {
        padding: 5px;
        font-size: 18px;
    }

    .post h1 {
        font-size: 28px;
        white-space: wrap;
    }
    
    .post h2 {
        font-size: 23px;
        white-space: wrap;
    }
}

@media screen and (max-width: 468px) {
    .post img {
        max-height: 300px;
        max-width: 300px;
    }

}