:root {
    --font-family: 'Kanit', sans-serif;

    --gradient-text: linear-gradient(89.97deg, #1a1cc0 1.84%, #f16822 102.67%);
    --gradient-bar: linear-gradient(103.22deg, #181c8b -13.86%, #f16822 99.55%);

    --color-bg: #040C18;
    --color-footer : #031B34;
    --color-blog: #042c54;
    --color-text: #81AFDD;
    --color-subtext: #FF8A71;
}

.section__padding {
    padding: 8rem 2rem;
    margin: 0rem 1rem;
}

.av {
    display: flex;
    flex-direction: column;
    background: var(--color-footer);
    background-size: contain;
    border-radius: 888px 55px 888px 55px;
    background-size: cover;
}

.gradient__text {
    background: var(--gradient-text);
    background-clip: text;
    -webkit-background-clop: text;
    -webkit-text-fill-color: transparent;
}

.avBrands {
    margin: 20px 0px;
}

.avBrand1 {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
}

.avBrand2 {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
}

.avBrand1 img {
    width: 333px;
    margin: 0px 10px;
}

.avBrand2 img {
    width: 222px;
    margin: 0px 10px;
}

.av h6 {
    font-size: 10px;
    letter-spacing: .08rem;
    color: whitesmoke;
    line-height: 16px;
    width: 100%;
    text-align: center;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-weight: 100;
}


.av__feature {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.av__feature h1 {
    font-family: var(--font-family);
    letter-spacing: 0.15rem;
    font-weight: 500;
    font-size: 55px;
    line-height: 50px;
    text-align: center;
    color: var(--gradient-text);
    padding-bottom: 30px;
}

.av__feature p {
    padding-bottom: 30px;
    font-size: 23px;
    text-align: center;
    color: var(--color-text);
    font-family: var(--font-family);
    letter-spacing: .13rem;
    text-align: center;
    text-shadow: rgb(1, 1, 1) 1px 1px 2px;
    text-transform: capitalize;
}

.av__feature .av__container {
    margin: 0;
}

.av__feature .av__container {
    max-width: 700px;
}

.av__heading {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 2.5rem 0;
}

.av__heading h3 {
    font-family: var(--font-family);
    font-size: 45px;
    font-weight: 800;
    line-height: 40px;
    max-width: 610px;
    color: #f16822;
    text-align: center;
}

.av__heading p {
    cursor: pointer;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 24px;
    letter-spacing: .13rem;
    color: rgb(150, 230, 191);
    text-shadow: 1px 1px 18px lime;
    line-height: 25px;
    text-align: center;
    display: flex;
    justify-content: center;
}

.av__container {
    display: flex;
    flex-wrap: wrap;
    margin-top: 1.5rem;
    justify-content: center;
    
    background-size:cover;
    background-repeat: no-repeat;
}

.av__container h1 {
    color: lime;
    letter-spacing: .35rem;
    font-weight: 400;
    font-size: 2.5rem;
}

.av__container p {
    font-family: 'Courier New', Courier, monospace;
    font-weight: 300;
    font-size: 23px;
    color: white;
}

@media screen and (max-width: 850px) {
    .av__heading {
        flex-direction: column;
        align-items: center;
        margin-bottom: 0rem;
    }

    .av__feature h1 {
        font-size: 38px;
    }

    .av__feature p {
        padding-bottom: 0;
    }

    .av__heading p {
        margin: 0;
        padding: 15px;
    }

    .avBrands img {
        max-width: 222px;
    }
}

@media screen and (max-width: 650px) {

    .av__feature .av__container__feature {
        flex-direction: column;
    }

    .av__feature .av__container__feature__text {
        margin-top: 0.5rem;
    }

    .av__heading h3 {
        font-size: 30px;
        line-height: 32px;
    }

    .av__feature p {
        font-size: 18px;
        line-height: 20px;
    }

    .av__heading h1 {
        font-size: 28px;
        line-height: 40px;
    }

    .avBrands img {
        max-width: 155px;
    }
}

@media screen and (max-width: 450px) {

    .av__container h1 {
        font-size: 33px;
    }

    .av.section__padding {
        padding: 1rem;
        margin: 0;
    }

    .av__feature h3 {
        font-size: 23px;
        line-height: 28px;
    }

    .av__feature h1 {
        font-size: 33px;
    }

    .avBrands img {
        max-width: 123px;
    }
}
