.type-text h1 {
    font-size: 2.5rem;
    font-family:'Courier New', Courier, monospace;
    color:lime;
    font-weight: 300;
}

.reveal {
    position: relative;
    opacity: 0;
}

.reveal.active {
    opacity: 1;
}
.active.type-text {
    animation: 
    typing 3.5s steps(28, end),
    blink-caret .75s step-end infinite;
}

.type-container {
    min-height: 150px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.type-text h1 {
    max-width: fit-content;
    overflow: hidden;
    text-align: center;
    overflow: hidden; 
    border-right: .15em solid; 
    white-space: nowrap; 
    margin: 0 auto; 
    letter-spacing: .15em; 
    animation: 
    typing 3.5s steps(40, end),
    blink-caret .75s step-end infinite;
}

@keyframes typing {
    from { width: 0 }
    to { width: 100% }
}

  /* The typewriter cursor effect */
@keyframes blink-caret {
    from, to { border-color: transparent }
    50% { border-color:lime; }
}

@media screen and (max-width: 900px) {
    .type-text h1 {
    font-size: 1.8rem;
    text-align: center;
    }
}

@media screen and (max-width: 700px) {
    .type-text h1 {
    font-size: 1.3rem;
    }
}

@media screen and (max-width: 480px) {
    .type-text h1 {
    font-size: 1rem;
    text-align: center;
    }
    
    .type-container {
        min-height: 80px;
    }
}

