.contact {
    padding: 1rem;
    background-image: linear-gradient(45deg,
    rgba(13, 16, 55, 0.867),
    rgba(32, 32, 33, 0.865)), url('../../assets/misc/startup-idea.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    overflow: hidden;

    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.hello {
display: block;
}

.form {
    width: 100%;
}

.msgTitle {
    color: rgb(255, 255, 255);
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-weight: 500;
    padding: 10px;
    letter-spacing: 1px;
    text-align: center;
}

.formBox p {
    color: lightgray;
    padding: 10px;
    font-size: 14px;
    max-width: 313px;
    text-align: center;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.formBox { 
    background: var(--color-footer);
    border: 6px solid #213346;
    border-radius: 15px;
    padding: 20px 10px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    margin-top: 50px;
}

.contact input.flat__button {
        background: var(--color-logoOrange);
        color: white;
        font-family: var(--font-family);
        font-weight: lighter;
        border: solid 5.5px var(--color-footer);
        border-radius: 8px ;
        letter-spacing: .5px;
        outline: none;
        cursor: pointer;
        height: 60px;
}

.contact .flat__button:hover {
    background-color: green;
    color: black;
}

.formBox ul li {
    padding: 10px 0px;
    list-style: none;
    
}

.formBox textarea {
    width: 100%;
    height: 189px;
    border-radius: 8px;
    font-size: 20px;
    padding-left: 5px;
}

.formBox label {
    color: aliceblue;
    margin-bottom: 5px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 16px;
}

.formBox input {
    width: 100%;
    font-size: 23px;
    border-radius: 8px;
    padding-left: 5px;
}

.formBox input::placeholder, textarea::placeholder {
    opacity: .6;
    color: rgb(66, 60, 60);
}

textarea::placeholder {
    font-size: 15px;
}

@media screen and (max-width: 1000px) {
    .formBox {
        position: absolute;
    }

    .form__control {
        width: 288px;
    }

    .form__message {
        width: 288px;
    }
}