:root {
    --font-family: 'Kanit', sans-serif;

    --color-bg: #040C18;
    --color-footer : #031B34;
    --color-blog: #042c54;
    --color-text: #81AFDD;
    --color-subtext: #FF8A71;
}

.explore {
display: flex;
flex-wrap: wrap;
padding: 5rem 1rem;
flex-direction: column;
background-image: url('../../assets/misc/spacey.jpg');
background-size: cover;
background-attachment: fixed;
background-repeat: no-repeat;
overflow: hidden;
}

.explore__title {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 2rem;
}

.explore__title h1 {
    font-size: 55px;
    color: rgb(1, 1, 1);
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    text-shadow: 2px 1px 10px lime;
    padding: 15px;
    letter-spacing: 1.5px;
    text-align: center;
}

.explore img {
    max-width: 700px;
}

.link__boxes {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 2rem;
}

.link__boxes h3 {
    padding: 10px;
    font-size: 23px;
    text-shadow: 2px 2px 13px rgb(0, 0, 0);
    color: whitesmoke ;
    font-weight: 500;
}

.card {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 350px;
    width: 300px;
    text-align: center; 
    font-family: var(--font-family);
}

.card .title1 {
    font-size: 40px;
    white-space: wrap;
    overflow: hidden;  
}

.contact__box {
    background-image: linear-gradient(45deg,
    rgba(10, 10, 11, 0.458),
    rgba(5, 5, 5, 0.412)), url('../../assets/misc/mobile_guy.png');
    background-size: contain;
    background-repeat: no-repeat;
    border: solid 3px #444;
    border-radius: 15px;
    margin: 10px;
}

.contact__box:hover {
    background-image: linear-gradient(45deg,
    rgba(10, 10, 11, 0.015),
    rgba(5, 5, 5, 0)), url('../../assets/misc/mobile_guy.png');
    transform: scale(1.1);
}

.card .title2 {
    font-size: 40px;
    white-space: wrap;
    overflow: hidden;
    position: relative;
    top: 123px;
}

.team__box {
    background-image: linear-gradient(45deg,
    rgba(10, 10, 11, 0.458),
    rgba(5, 5, 5, 0.412)), url('../../assets/misc/pc_guy.png');
    background-size: contain;
    background-position: top center;
    background-repeat: no-repeat;
    border: solid 3px #444;
    border-radius: 15px;
    margin: 10px;
}

.team__box:hover {
    background-image: linear-gradient(45deg,
    rgba(10, 10, 11, 0.015),
    rgba(5, 5, 5, 0)), url('../../assets/misc/pc_guy.png');
    transform: scale(1.1);
}

.card .title3 {
    font-size: 40px;
    white-space: wrap;
    overflow: hidden;
    position: relative;
    top: 90px;
}

.work__box {
    background-image: linear-gradient(45deg,
    rgba(10, 10, 11, 0.458),
    rgba(5, 5, 5, 0.412)), url('../../assets/misc/guy_with_glasses.png');
    background-repeat: no-repeat;
    background-size: contain;
    border: solid 3px #444;
    border-radius: 15px;
    margin: 10px;
}

.work__box:hover {
    background-image: linear-gradient(45deg,
    rgba(10, 10, 11, 0.015),
    rgba(5, 5, 5, 0)), url('../../assets/misc/guy_with_glasses.png');
    transform: scale(1.1);
}

.card .title4 {
    font-size: 40px;
    white-space: wrap;
    overflow: hidden;
    position: relative;
}

.blog__box  {
    background-image: linear-gradient(45deg,
    rgba(10, 10, 11, 0.458),
    rgba(5, 5, 5, 0.412)), url('../../assets/misc/seo_illustrations_8.png');
    background-size: contain;
    border: solid 3px #444;
    border-radius: 15px;
    margin: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.blog__box:hover {
    background-image: linear-gradient(45deg,
    rgba(10, 10, 11, 0.015),
    rgba(5, 5, 5, 0)), url('../../assets/misc/seo_illustrations_8.png');
    transform: scale(1.1);
}

@media screen and (max-width: 1250px){
    .explore img {
        max-width: 400px;
    }
}

@media screen and (max-width: 550px) {
    .explore { 
        padding: 4rem 0rem;
    }

    .explore h1 {
        font-size: 35px;
    }

    .explore img {
        width: 280px;
    }
}

@media screen and (max-width: 550px) {
    
    .explore__title {
        padding: 5px;
        width: 100%;
    }

    .explore h1 {
        font-size: 28px;
        padding: 0px;
        white-space: wrap;
    }

    .link__boxes {
        padding: 0;
        margin: 10px 0px;
    }
}