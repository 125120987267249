:root {
    --font-family: 'Kanit', sans-serif;

    --gradient-text: linear-gradient(69.69deg, #f16822 8.88%, #76a0b8 69.69%);
    --gradient-bar: linear-gradient(103.33deg, #76a0b8 -13.33%, #f16822 99.55%);

    --color-bg: #040C18;
    --color-footer : #031B34;
    --color-blog: #042c54;
    --color-text: #81AFDD;
    --color-subtext: #FF8A71;
}

.gradient__text {
    background: var(--gradient-text);
    background-clip: text;
    -webkit-background-clop: text;
    -webkit-text-fill-color: transparent;
}

.brand {
    display: flex;
    width: 100%;
}

.clients {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: var(--color-footer);
    border: solid 3px #444;
    border-radius: 15px;
    width: 100%;
    font-family: var(--font-family);
}

.brandTitle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 70%;
    letter-spacing: .13rem;
}

.brandTitle h1 {
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    line-height: 31px;
    font-size: 29px;
    letter-spacing: .23rem;
    color:#76a0b8;
    text-align: center;
}

.textChange {
    font-size: 33px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding: 10px;
    text-align: center;
}

.textChange h2 {
    color: var(--gradient-text);
}

.clients img {
    max-width: 444px;
}

.clients button {
    background:#76a0b8;
    font-size: 20px;
    padding: 13px;
    min-width: fit-content;
    color: white;
    border: solid 1.5px var(--color-footer);
    border-radius: 8px;
    letter-spacing: 2px;
    outline: none;
    cursor: pointer;
    margin-top: 15px;
}

.clients button:hover {
    transform: scale(1.1);
    font-weight: 600;
    background-color: var(--color-logoOrange);
}


.section__padding {
    padding: 0rem 2rem 1rem 2rem;
}

@media screen and (min-width: 1400px) {
    .clients {
        justify-content: center;
    }

    .brandTitle {
        width: 50%;
    }

}

@media screen and (max-width: 1100px) {
    .brandTitle h1 {
        font-size: 23px;
        line-height: 25px;
    }
    
    .textChange {
        font-size: 25px;
    }

    .clients img {
        max-width: 380px;
    }

}

@media screen and (max-width: 895px) {
    .clients img {
        max-width: 300px;
    }

    .brandTitle h1 {
        font-size: 20px;
        line-height: 21px;
    }
    
    .textChange {
        font-size: 22px;
        line-height: 33px;
    }
}

@media screen and (max-width: 770px) {
    .clients img {
        max-width: 250px;
    }

    .brandTitle h1 {
        font-size: 18px;
        line-height: 20px;
        padding-bottom: 50px;
    }
    
    .textChange {
        font-size: 20px;
        text-align: center;
        position: absolute;
    }

    .clients button {
        padding: 10px;
        font-size: 16px;
    }

}

@media screen and (max-width: 685px) {
    .clients img {
        max-width: 200px;
    }

    .brandTitle h1 {
        font-size: 15px;
        line-height: 16px;
    }
    
    .textChange {
        font-size: 17px;
        text-align: center;
        position: absolute;
    }

}

@media screen and (max-width: 590px) {
    .clients img {
        max-width: 180px;
    }

    .brandTitle h1 {
        font-size: 14px;
        line-height: 15px;
        margin-bottom: 15px;
    }
    
    .textChange {
        font-size: 15px;
        text-align: center;
        position: absolute;
        overflow: hidden;
    }

    .clients button {
        padding: 8px;
        font-size: 14px;
        margin-top: 0px;
    }
}

@media screen and (max-width: 535px) {
    .clients img {
        max-width: 150px;
    }
    
    .textChange {
        font-size: 14px;
        padding: 5px;
        line-height: 20px;
    }

    .clients button {
        padding: 6px;
        font-size: 12px;
        margin: 0px 0px 20px 0px;
    }
}

@media screen and (max-width: 480px) {
    .clients img {
    display: none;
    }

    .clients {
        height: 380px;
        min-width: 300px;
        background-image: linear-gradient(45deg,
        rgba(10, 10, 11, 0.702),
        rgba(5, 5, 5, 0.63)), url('../../assets/misc/boy.png');
        background-size: 430px;
        flex-direction: column;
    }

    .brandTitle{
        justify-content: space-evenly;
        width: 100%;
    }

    .brandTitle h1 {
        width: 300px;
        padding: 0px;
        margin: 0;
        line-height: 23px;
        font-size: 23px;
        letter-spacing: .23rem;
        color:#76a0b8;
        text-align: center;
        
    }
    
    .textChange {
        font-size: 28px;
        line-height: 50px;
        letter-spacing: .222rem;
        text-align: center;
        position: relative;
        width: 300px;
        min-height: 222px; 
        align-items: center;
    }

    .clients button {
        padding: 13px;
        font-size: 16px;
        margin: 0px;
    }

    .section__padding {
        padding: .5rem;
    }
}

